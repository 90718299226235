export default class BD3DBuildInfo {
  static getVersion() {
    // Don't change this value!
    // It will be replaced with the current version after building
    return '1.10.0-157-gb3ca7d3';
  }

  static get version() {
    return this.getVersion();
  }

  static getBuildDate() {
    // Don't change this value!
    // It will be replaced with the current build time after building
    return '2022/01/14 10:55:19';
  }

  static getBuildMode() {
    // Don't change this value!
    // It will be replaced with the current build mode after building
    return 'production';
  }
}
